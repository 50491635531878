import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {getUserSearchResults} from '../../store';
import {select, Store} from '@ngrx/store';
import * as rootState from '../../store';
import {SearchUser} from '../../store/user/user.actions';

@Component({
  selector: 'app-team-form',
  template: `
    <form [formGroup]="formGroup" class="form" (ngSubmit)="formSubmitted.emit(formGroup.value)">
      <div class="form__field">
        <label class="form__label">Name</label>
        <input type="text" class="form__input" formControlName="name">
      </div>
      <div class="form__field">
        <label class="form__label">Description</label>
        <textarea class="form__input" formControlName="description"></textarea>
      </div>
      <div class="form__field" *ngIf="!team.admins">
        <label class="form__label">Admin</label>
        <ng-container *ngIf="!selectedUser">
          <app-search (onSearch)="search($event)"></app-search>
          <ul class="autocomplete-list">
            <li class="autocomplete-list__item" *ngFor="let user of (userSearchResult$ | async)"
                (click)="selectUser(user)">
              {{ user.first_name }} {{ user.last_name }}<br>
              <small>{{ user.email }}</small>
            </li>
          </ul>
        </ng-container>
        <ng-container *ngIf="selectedUser">
          <div class="flex u-flex-center-vertically">
            <div class="flex__1">
              {{ selectedUser.first_name }} {{ selectedUser.last_name }}
            </div>
            <div>
              <button type="button" (click)="selectUser(null)" class="button button--small">Change</button>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="flex flex--gap u-margin-top-l">
        <div class="flex__2"></div>
        <div class="flex__1" *ngIf="!hideCancelButton">
          <button type="button" class="button button--fullwidth" (click)="cancel.emit()">
            Cancel
          </button>
        </div>
        <div class="flex__1">
          <button type="submit" class="button button--primary button--fullwidth" [disabled]="!formGroup.valid || isLoading">
            Save
          </button>
        </div>
      </div>
    </form>
  `
})
export class TeamFormComponent implements OnInit {
  @Input() team: any;
  @Input() isLoading: boolean;
  @Input() hideCancelButton: boolean;
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  userSearchResult$: Observable<any[]>;
  formGroup: FormGroup;
  selectedUser: any;

  constructor(private store: Store<rootState.IAppState>) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(this.team.name, Validators.compose([Validators.required])),
      description: new FormControl(this.team.description, null),
    });

    // If this is a new team we need to add an admin
    if (!this.team.id) {
      this.formGroup.addControl('adminId', new FormControl(null, Validators.compose([Validators.required])));
    }

    this.userSearchResult$ = this.store.pipe(select(getUserSearchResults));
  }

  selectUser(user: any): void {
    this.selectedUser = user;

    if (user) {
      this.formGroup.controls.adminId.setValue(user.id);
    } else {
      this.formGroup.controls.adminId.setValue(null);
    }
  }

  search(term: string): void {
    this.store.dispatch(new SearchUser(term));
  }
}
